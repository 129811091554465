<script setup>
import Notification from './Notification.vue'
import useSubscriptionService from 'share/services/payment/useSubscriptionService'
import useNotificationService from 'share/services/notification/useNotificationService'
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import { storeToRefs } from 'pinia'

const { isLoggedIn, memberInfo } = storeToRefs(useMemberSessionStore())

const { fetchSubscriptionInfo, myPlanString, myPlanButtonString, subscriptionInfo } = useSubscriptionService()
const { existUnread, fetchNotificationList } = useNotificationService()

const emit = defineEmits(['onClick'])
const props = defineProps({
  tag: {
    type: String,
    default: 'header',
  },
  align: {
    type: String,
    default: 'space-between',
  },
  title: {
    type: String,
  },
  type: {
    type: String,
    default: 'logo',
  },
  sticky: {
    type: Boolean,
    default: false,
  },
  hasBeta: {
    type: Boolean,
    default: false,
  },
  skipDefault: {
    type: Boolean,
    default: false,
  },
})

const onClick = () => {
  emit('onClick')
}

onMounted(async () => {
  if (isLoggedIn.value) {
    fetchSubscriptionInfo()
    fetchNotificationList()
    await getMenuStatus()
  }
})
</script>

<template>
  <component
    :is="tag"
    class="header"
    :class="{ empty: type === 'empty', sticky: sticky }"
    :style="`justify-content: ${align}`"
  >
    <template v-if="type === 'logo'">
      <h1 class="header__logo flex items-center">
        <button class="header__logo-link" @click="onClick">
          <strong class="blind">The Pond</strong>
        </button>
        <mark
          class="m-left-8 radius-200 border-a-primary3-1 p-x-6 h-16 text-caption2 text-primary4 inline-block"
        >
          Beta
        </mark>
      </h1>
    </template>
    <h1 class="header__title" v-if="type === 'text'">
      {{ title }}
    </h1>
    <slot name="actions"></slot>
    <template v-if="!skipDefault && type !== 'empty'"> <!-- Check type !== 'empty' -->
      <template v-if="isLoggedIn">
        <article
          style="margin-left: auto; margin-right: 16px"
          v-if="['trialing', 'active'].includes(subscriptionInfo.status) === false"
        >
          <UiButton class="m-y-16 w-100-p small" style="white-space: pre" @click="navigateTo('/purchase/subscription')">
            Upgrade
          </UiButton>
        </article>
        <article style="margin-left: auto; margin-right: 16px" v-else>
          <UiLabel class="small border blue" style="white-space: nowrap">
            {{ myPlanString }}
          </UiLabel>
        </article>
      </template>
      <Notification icon-color="#000" />
    </template>
  </component>
</template>


<style scoped lang="scss">
.header {
  height: 56px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: $white;
  border-bottom: 1px solid $t2;
  &.sticky {
    position: sticky;
    top: 0;
    z-index: 10;
  }
  &.empty {
  }
  &__logo {
    &-link {
      width: 110px;
      height: 25px;
      display: block;
      background: url('@share/assets/images/logo.svg') no-repeat 0 0 / contain;
    }
  }
  &__title {
    @include set-text;
    color: $black;
  }
  :deep(.header__title) {
    @include set-text($weight: 700);
    color: $black;
  }
  :deep(.header-button) {
    height: 24px;
  }
  :deep(.header-left-button) {
    height: 24px;
    & + .header__title {
      margin-left: 8px;
    }
  }
  :deep(.header-right-icon) {
    height: 24px;
  }
}
</style>
