<script setup>
import useNotificationService from 'share/services/notification/useNotificationService'

const emit = defineEmits(['cancel'])

const closeModal = () => {
  emit('cancel')
}

useCustomBackButton(closeModal)

const {
  notificationList,
  fetchNotificationList,
  handleGo,
  convertDateFormat
} = useNotificationService()

const handleMGo = (noti) => {
  handleGo(noti)
  setTimeout(closeModal, 350)
}

onMounted(() => {
  fetchNotificationList()
})

</script>

<template>
  <LayoutModal modal-id="ModalNotification" fix-bottom is-scroll type="full" header-type="empty" :closeConfirm="false">
    <LayoutHeader type="empty" sticky>
      <template v-slot:actions>
        <h1 class="text-body1-b modal-title text-black flex items-center">
          Notifications
        </h1>
        <button class="header-right-icon" @click="closeModal">
          <IconClose width="24" height="24" color="#434247"/>
        </button>
      </template>
    </LayoutHeader>
    <section class="modal-content w-100-p" style="height: calc(100% - 56px)">
      <!--      알림있을때-->
      <ul v-if="notificationList.length > 0">
        <!--        true 읽은상태일때-->
        <li
          v-for="(noti, index) in notificationList"
          class="p-a-16 border-bottom-gray2-1 cursor-pointer flex justify-between items-start"
          :class="noti.read ? 'bg-white' : 'bg-primary1'"
        >
          <img src="@share/assets/images/logo-symbol-circle.svg" alt="" class="w-40 h-40 flex-none">
          <div
            class="m-left-10"
            style="calc(100% - 50px)"
            @click="handleMGo(noti)"
          >
            <p class="text-caption1 text-black m-bottom-12">
              {{ noti.message }}
            </p>
            <small class="text-caption3 text-t4">
              {{ convertDateFormat(noti.createdAt) }}
            </small>
          </div>
        </li>
      </ul>
      <!--      알림있을때-->
      <!--      알림없을때-->
      <p v-else class="text-body1-b text-center" style="padding-top: 19vh">
        <IconNotification width="44" height="44"/><br/>
        No Notifications Yet
      </p>
      <!--      알림없을때-->
    </section>
  </LayoutModal>
</template>

<style scoped lang="scss">
</style>
