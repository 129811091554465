<script setup lang="ts">
import { useModal } from 'vue-final-modal'
import Notification from '~/components/modal/Notification.vue'
import { storeToRefs } from 'pinia'
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import useNotificationService from 'share/services/notification/useNotificationService'

const props = defineProps({
  iconColor: {
    type: String,
    default: '#0038FF'
  }
})

const { isLoggedIn, memberInfo } = storeToRefs(useMemberSessionStore())
const {
  existUnread,
  fetchNotificationList
} = useNotificationService()

const handleShowNotificationModal = () => {
  const { open, destroy } = useModal({
    component: Notification,
    attrs: {
      onCancel: () => destroy(),
    }
  })
  open()
}

onMounted(() => {
  if (isLoggedIn.value) {
    fetchNotificationList()
  }
})
</script>

<template>
  <button
    v-if="isLoggedIn"
    class="flex-none w-40 h-40 radius-200 bg-white position-relative"
    @click="handleShowNotificationModal"
  >
    <mark
      v-if="existUnread"
      class="w-6 h-6 inline-block bg-red radius-200 position-absolute top-7 right-8"
    ></mark>
    <IconAlert width="24" height="24" :color="iconColor" />
  </button>
</template>

<style scoped lang="scss">

</style>
